.about-us{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 ;
    padding: 390px 0 0 0px;
    background: radial-gradient(62.36% 62.36% at 50% 50%, #BAEFFE 0%, #2577CA 100%);

    &_title{
        display: block;
        font-family: 'Ruslan Display', cursive;
        font-size: 46px;
        color: #FDCE26;
        margin: 180px 0 60px;
    }

    &_token-info{
        display: flex;
        width: 100%;
        max-width: 1284px;
        justify-content: space-around;
        margin: 0px 0 0px;
    }
    
    &_backgound{
        position: absolute;
        width: 100%;
        height: 3746px;
        top: -378px;
        background: radial-gradient(62.36% 62.36% at 50% 50%, #BAEFFE 0%, #2577CA 100%);
        z-index: -1;
    }

    &_info{
        display: flex;
        justify-content: space-between;
        max-width: 1284px;
        width: 100%;
    }
}

.case-yellow{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 586px;
    background:radial-gradient(62.36% 62.36% at 50% 50%, #FFEBA6 0%, #FDCE26 100%);
    margin:0 0 95px;
    -webkit-filter: drop-shadow(0px 5px 4px #00000024);
        filter: drop-shadow(0px 5px 4px #00000024);
    &>span{
        display: block;
        max-width: 529px;
        font-family: 'Montserrat', sans-serif; 
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        &:first-child{
            margin: 33px 0 10px;
        }
        &:last-child{
            font-size: 20px;
            font-weight: 400;
            margin: 0 0 34px;
        }
    }
}


.info-case{
    display: flex;
    justify-content: space-between;
    max-width: 1284px;
    width: 100%;
    margin: 0px 0 100px;
}

.nft-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 667px;

    &_title{
        font-family: 'Montserrat', sans-serif; 
        font-size: 20px;
        font-weight: 600;
        max-width: 270px;
    }

    &_title-sub{
        font-family: 'Montserrat', sans-serif; 
        font-size: 20px;
        font-weight: 400;
    }
    &>span{
        margin: 0 0 20px;
    }
}

.nft-treal{
    width: 490px;
    height: 490px;
    border:10px solid #2577CA;
    animation: bee 15s;
    background-size: cover;
    animation-iteration-count:infinite;
    -moz-animation-iteration-count:infinite;
    -webkit-animation-iteration-count:infinite;
    animation-timing-function: steps(1, start);
    -webkit-filter: drop-shadow(0px 5px 4px #00000024);
    filter: drop-shadow(0px 5px 4px #00000024);
    &_visual{
        position: relative;
        display: flex;
        justify-content: center;
        -webkit-filter: drop-shadow(0px 5px 4px #00000024);
        filter: drop-shadow(0px 5px 4px #00000024);
        
        &>img{
            position: absolute;
            width: 100%;
        }
    }
}

.i-am-ukrainian{
    
    &_title{
        display: block;
        max-width:558px;
        font-family: 'Ruslan Display', cursive;
        font-size: 46px;
        color: #2577CA;
        text-align: center;
        margin: 150px auto 60px
    }
}

.city-heart{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 1110px;
    -webkit-filter: drop-shadow(0px 5px 4px #00000024);
        filter: drop-shadow(0px 5px 4px #00000024);
    &_arrow{
        position: absolute;
        top: -10px;
        left: -1105px;
        z-index: 12;
        pointer-events: none;
    }

    &>div{
        position: relative;
        display: flex;
        &>img{
            pointer-events:none
        }
        
    }

    &_leir0{
        margin: 0 auto;
        div{
            display: flex;
        }

        &__first{
            position: relative;
            left: -106px;
            div{
                transition: 1s;
                &:hover{
                    transform: scale(1.1);
                }
            }
        }
        &__last{
            position: relative;
            left: 106px;
            div{
                transition: 1s;
                &:hover{
                    transform: scale(1.1);
                }
            }
        }
    }

    &_leir1{
        top: -34px;
        margin: 0 auto;
        div{
            transition: 1s;
            &:hover{
                transform: scale(1.1);
            }
        }
    }
    &_leir2{
        top: -64px;
        div{
            transition: 1s;
            &:hover{
                transform: scale(1.1);
            }
        }
    }

    &_leir3{
        top: -100px;
        margin: 0 auto;
        div{
            transition: 1s;
            &:hover{
                transform: scale(1.1);
            }
        }
    }

    &_leir4{
        top: -140px;
        margin: 0 auto;
        div{
            transition: 1s;
            &:hover{
                transform: scale(1.1);
            }
        }
    }

    &_leir5{
        top: -180px;
        margin: 0 auto;
        div{
            transition: 1s;
            &:hover{
                transform: scale(1.1);
            }
        }
    }
}


.bee {
    position: absolute !important;
    top: 588px;
    left: -852px;
    z-index: 11;

    &_0{
        position: relative;
        left: 806px;
        top: -240px;
    }
    &_1{
        position: relative;
        left: 566px;
        top: -140px;
    }
    &_2{
        position: relative;
        left: 290px;
        top: -40px;
    }
    &_3{
        position: relative;
        z-index: 2;
    }
    &_4{
        position: relative;
        right: 290px;
        top: -40px;
        z-index: 1;
    }
    &_5{
        position: relative;
        right: 592px;
        top: -124px;
        z-index: 0;
    }
    &_6{
        position: relative;
        right: 846px;
        top: -230px;
        z-index: -1;
    }
}