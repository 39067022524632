.ambasador-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: radial-gradient(62.36% 62.36% at 50% 50%, #BAEFFE 0%, #2577CA 100%);
}


.amb-case{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 0;
    width: 100%;
    height: 1024px;
    z-index: 1;
}

.mint-amb{

    display: flex;
    flex-direction: column;
    align-items: center;

}


.top-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1440px;
    &>.sosial-box{
        position: relative;
        height: 34px;
        top: auto;
        right: inherit;
    }
}

.header-box{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    &>.sosial-box{
        position: relative;
        right: 0;
        top: 0;
    }

    &>.header_leng{
        display: flex;
    }
}


.bg{
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 1068px;
}

.bg-mint-amb{
    position: absolute;
    top: 0;
    right: 0;
    width: 1440px;
    height: 1016px;
    background-image: url(./img/соти.png);
    background-size: cover;
    z-index: 0;

}

.bg-bot-mint-amb{
    position: absolute;
    bottom: 0;
    right: -19px;
    width: 1920px;
    height: 62px;
    background-image: url(./img/соти-полоска.png);
    z-index: 2;
}

.logo-mint-amb{
    position: relative;
    width: 211px;
    z-index: 1;
}

.bee-mint-amb{
    position: absolute;
    width: 641px;
    left: 0;
    bottom: 0px;
    z-index: 2;
    pointer-events: none;
}

.kalina-mint-amb{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
}

.img-hive-box-amb{
    position: absolute;
    right: 20px;
    bottom: 90px;
    pointer-events: none;
}

.hive-mint-amb{
    position: relative;
    z-index: 0;
}

.klinavinok-mint-amb{
    position: absolute;
    left: -46px;
    bottom: -90px;
 
}

.box-mint-case-amb{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px 0 0;
    background: radial-gradient(62.36% 62.36% at 50% 50%, #BAEFFE 0%, #2577CA 100%);
    border-radius: 50px;
    padding: 68px;

    &_title{
        width: 539px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 44px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 25px;
    }

    &_inf{
        width: 424px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 50px;
    }
}

@media (max-width:1400px) {

    .bee-mint-amb {
        width: 471px;
    }

    .kalina-mint-amb{
        width: 770px;
    }

    .amb-case{
        height: 862px;
    }

    .bg{
        height: 900px;
    }

    .bg-mint-amb{
        width: 1199px;
        height: 856px;
    }

    .bg-bot-mint-amb{
        right: -35px;
        height: 54px;
        background-size: contain;
    }
}

@media (max-width:1250px){
    .box-mint-case-amb{
        &_title{
            font-size: 28px;
        }

        &_inf{
            font-size: 22px;
        }
    }

    .connect-box{
        &>span{
            font-size: 36px;
        }
    }

    .img-hive-box-amb{
        &>.hive-mint-amb{
            width: 230px;
        }

        &>.klinavinok-mint-amb{
            width: 310px;
        }
    }
}

@media (max-width:1100px){
    .bee-mint-amb {
        width: 311px;
    }

    .kalina-mint-amb {
        width: 620px;
    }

    .box-mint-case-amb{
        &_title{
            width: 359px;
            font-size: 24px;
            line-height: 24px;
            margin-bottom: 15px;
        }

        &_inf{
            width: 329px;
            font-size: 18px;
            line-height: 19px;
            margin-bottom: 30px;
        }
    }
}


@media (max-width:900px){
    .kalina-mint-amb {
        display: none;
    }

    .amb-case {
        height: 751px;
    }

    .bg {
        height: 786px;
    }

    .bg-mint-amb {
        width: 1009px;
        height: 746px;
    }

    .bg-bot-mint-amb {
        right: -13px;
        height: 47px;
    }

    .img-hive-box-amb{
        display: none;
    }
}

@media(max-width:750px){

    .bg-mint-amb {
        width: 783px;
        height: 556px;
    }

    .bg-bot-mint-amb{
        display: none;
    }
    

    .box-mint-case-amb{
        margin: 45px 0 0;
        padding: 38px;
        &_title{
            width: 359px;
            font-size: 24px;
            line-height: 24px;
            margin-bottom: 15px;
        }

        &_inf{
            width: 329px;
            font-size: 18px;
            line-height: 19px;
            margin-bottom: 30px;
        }
    }

    .bee-mint-amb {
        display: none;
    }

    .box-join {
        padding: 480px 0 0;
    }
}