.geks-display{
    display: flex;
    flex-direction: column;
    margin: 150px 0 0 ;
}

.geks{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 341px;
    height: 393px;
    &_text{
        display: block;
        width: 301px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        font-variant: small-caps;
        color: #ffffff;
    }
}

.geks-box{
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin: 100px 0 0 ;
}

.geks-title{
    font-family: 'Ruslan Display';
    font-style: normal;
    font-weight: 400;
    font-size: 46px;
    line-height: 50px;
    text-align: center;
    color: #FFFFFF;
}

.first-line{
    display: flex;
    justify-content: space-between;
    width: 1069px;
}

.midle-line{
    position: relative;
    top: -84px;
    left: 1px;
    display: flex;
    justify-content: space-between;
    width: 704px;
}

.botton-line{
    position: relative;
    top: -168px;
    left: 2px;
}

.bold{
    font-weight: 600; 
}

.g-vialet{
    background-image: url(./img/gv.svg);
}
.g-blue {
    background-image: url(./img/gb.svg);
}

.g-green {
    background-image: url(./img/gg.svg);
}

.g-pink {
    background-image: url(./img/gp.svg);
}

.g-red {
    background-image: url(./img/gr.svg);
}

.g-yellow {
    background-image: url(./img/gy.svg);
}

@media (max-width:1082px){
    .geks-box{
        margin: 10px 0 0;
    }
    .first-line{
        flex-wrap: wrap;
        justify-content: space-around;
        width: 704px;
    }
    .g-vialet{
        position: relative;
        top: 85px;
        margin-right: 5px;
    }
    .g-blue{
        position: relative;
        top: 85px;
        margin-left: 5px;
    }
}

@media (max-width:720px){
    .geks-box{
        margin: 10px 0 0;
    }

    .first-line{
        flex-wrap: wrap;
        width: 341px;
    }

    .g-vialet{
        top: 0px;
        margin-right: 0px;
    }

    .g-blue{
        top: 0px;
        margin-left: 0px;
    }

    .midle-line{
        width: 341px;
        flex-direction: column;
        top: 0px;
    }
    
    .botton-line{
        top:0;
    }

    .geks{
        margin-bottom: 10px;
    }

    .logo-mint-amb {
        width: 151px;
    }

    .connect-box > span {
        margin-bottom: 20px;
    }
}

@media(max-width:550px){

}