.spiner{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    z-index: 200;
    transition: 0.5s;
    top:0;
    left:0;

    &_bg{
        position: fixed;
        height: 100%;
        width: 100%;
        background: radial-gradient(62.36% 62.36% at 50% 50%, #BAEFFE 0%, #2577CA 100%);
        z-index: 210;
    }
}

.spiner__dis{
    display: none;
}

.case-spiner{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    &>div{
        position: relative;
        display: flex;
        justify-content: center;
        &>img{
            position: relative;
            margin: 0px 2px;
            z-index: 211;
        }
    }

    &>div:first-child, &>div:last-child{
        width: 72%;
    }
    &>div:first-child{
        top:10px
    }
    &>div:last-child{
        top:-10px
    }
}

.case-spiner_sot0{
    animation: spin0 5s linear infinite;
}

.case-spiner_sot1{
    animation: spin1 5s linear infinite;
}

.case-spiner_sot4{
    animation: spin2 5s linear infinite;
}
.case-spiner_sot6{
    animation: spin3 5s linear infinite;
}
.case-spiner_sot5{
    animation: spin4 5s linear infinite;
}

.case-spiner_sot2{
    animation: spin5 5s linear infinite;
}

@keyframes spinAll {
    0%{
        transform: rotate(0deg);
    }
    0%{
        transform: rotate(360deg);
    }
}

@keyframes spin0 {
    0%{
        opacity: 0;
    }

    10%{
        opacity: 1;
    }

    30%{
        opacity: 1;
    }

    40%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
}

@keyframes spin1 {
    0%{
        opacity: 0;
    }

    10%{
        opacity: 0;
    }

    20%{
        opacity: 1;
    }

    40%{
        opacity: 1;
    }

    50%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
}

@keyframes spin2 {
    0%{
        opacity: 0;
    }


    20%{
        opacity: 0;
    }

    30%{
        opacity: 1;
    }

    50%{
        opacity: 1;
    }

    60%{
        opacity: 0;
    }


    100%{
        opacity: 0;
    }
}

@keyframes spin3 {
    0%{
        opacity: 0;
    }

    30%{
        opacity: 0;
    }

    40%{
        opacity: 1;
    }

    60%{
        opacity: 1;
    }

    70%{
        opacity: 0;
    }

    100%{
        opacity: 0;
    }
}

@keyframes spin4 {
    0%{
        opacity: 0;
    }

    40%{
        opacity: 0;
    }

    50%{
        opacity: 1;
    }

    70%{
        opacity: 1;
    }

    80%{
        opacity: 0;
    }

    100%{
        opacity: 0;
    }
}

@keyframes spin5 {
    0%{
        opacity: 0;
    }

    50%{
        opacity: 0;
    }

    60%{
        opacity: 1;
    }

    80%{
        opacity: 1;
    }

    90%{
        opacity: 0;
    }

    100%{
        opacity: 0;
    }
}
