.faq{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: radial-gradient(62.36% 62.36% at 50% 50%, #FFEBA6 0%, #FDCE26 100%);
    padding: 0px 0 60px;
    &_case{
        max-width: 1284px;
        margin: 10px 0 0;
    }

    &_title{
        display: block;
        font-family: "Ruslan Display", cursive;
        font-size: 46px;
        color: #2577CA;
        text-align: center;
        margin: 150px 0 60px;
    }
}

.faq-drop-case{
    overflow: hidden;
    margin: 5px 0;
    transition: 0.5s;
    &_button{
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 1055px;
        height: 73px;
        border: 0 solid transparent;
        background: radial-gradient(62.36% 62.36% at 50% 50%, #BAEFFE 0%, #2577CA 100%);
        margin: 5px 0;

        img{
            width: 15px;
            height: 10px;
            margin: 0 30px 0 55px;
        }
        span{
            display: block;
            font-family: 'Montserrat', sans-serif; 
            font-size: 24px;
            font-weight: 600;
            text-align: start;
        }
    }

    &_info{
        max-width: 900px;
        font-family: 'Montserrat', sans-serif; 
        font-size: 20px;
        font-weight: 400;
        text-align: start;
        margin: 20px 0 0;
        overflow: hidden;
        padding: 0 10px;
        white-space: break-spaces;
    }
}

.act{
    max-height: 473px;
    &>button{
        color: #ffffff;
    }
}

.dis{
    max-height: 78px;
}
