.box-join{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 1308px;
    padding: 1060px 0 0;
    margin: 150px 0 0;
}

.title-join{
    font-family: 'Ruslan Display';
    font-style: normal;
    font-weight: 400;
    font-size: 46px;
    line-height: 50px;
    text-align: center;
    color: #FFFFFF;
    margin: 0 0 100px;
}

.join{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 522px;
    padding: 68px 38px 42px; 
    background: radial-gradient(62.36% 62.36% at 50% 50%, #FFEBA6 0%, #FDCE26 100%);
    margin: 0 0 64px;
 
    &_title{
        display: block;
        font-family: "Ruslan Display", cursive;
        font-size: 24px;
        color: #333;
        text-align: center;
        margin: 64px auto 25px;
    }

    &_info{
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        white-space: break-spaces;
    }
    &>a{
        &>button {
            width: 218px;
            height: 49px;
            border: 3px solid #2577CA;
            background: transparent;
            color: #2577CA;
            font-family: 'Montserrat', sans-serif;
            font-size: 24px;
            font-weight: 600;
            text-align: center;
            cursor: pointer;
            transition: 0.5s;
            margin: 30px 0 42px;
            &:hover {
                color: #FDCE26;
                background: #2577CA;
            }
        }
    }
}

.join-case{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media (max-width: 1400px){
    .box-join{
        padding: 870px 0 0;
    }
}

@media (max-width: 1235px){
    .join{
        width: 342px;
    }

    .join-case{
        justify-content: space-evenly;
    }
}

@media(max-width:750px){
    .box-join {
        padding: 480px 0 0;
    }
}