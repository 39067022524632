.pop-up-mint{
    position:fixed ;
    display: flex;
    max-width: 1440px;
    width: 100%;
    max-height: 1024px;
    height: 100%;
    background: radial-gradient(62.36% 62.36% at 50% 50%, #BAEFFE 0%, #2577CA 100%);
    filter: drop-shadow(6px 4px 5px rgba(51, 51, 51, 0.7019607843));
    z-index: 100;
    left: calc((100% - 1440px) / 2);
    opacity: 1;
    transition: all 1s;
    &_dis{
        display: none;
        transition: all 1s;
        opacity: 0;
    }
}

.mint-x{
    position: absolute;
    right: 10px;
    top:10px;
    z-index: 120;
    cursor: pointer;
    transition: all 1s;
    filter: drop-shadow(6px 4px 5px rgba(51, 51, 51, 0.7019607843));

    &:hover{
        transform: scale(1.1);
    }

    &>div{
        border-color: #ffffff;
        background-color: #ffffff;
    }
}

.bg-mint{
    position: absolute;
    width: 100%;
    height: 100%;
    background-image:url(../img/mint/sot.png) ;
    background-size: cover;
}

.box-mint-case{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 706px;
    margin: auto auto;
    width: 100%;
    height: 350px;
    background: radial-gradient(62.36% 62.36% at 50% 50%, #BAEFFE 0%, #2577CA 100%);
    border-radius: 50px;
    z-index: 102;
}

.bee-mint{
    position: absolute;
    width: 641px;
    bottom: 0px;
    z-index: 111;
    pointer-events: none;
}

.logo-mint{
    position: absolute;
    top: 33px;
    left: 86px;
    width: 211px;
    height: 155px;
}

.img-hive-box{
    position: absolute;
    right: 20px;
    bottom: 90px;
    pointer-events: none;
}

.hive-mint{
    position: relative;
    z-index: 0;
}

.klinavinok-mint{
    position: absolute;
    left: -46px;
    bottom: -90px;
 
}

.kalina-mint{
    position: absolute;
    left: 0;
    z-index: 110;
    pointer-events: none;
}

.button-yello-mint{
    display: block;
    width: 218px;
    height: 49px;
    background: radial-gradient(62.36% 62.36% at 50% 50%, #FFEBA6 0%, #FDCE26 100%);
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
    border: 0px;
    cursor: pointer;
    transition: all 1s;
    margin: 5px auto 0;
    &:hover{
        transform: scale(1.2);
    }
}

.sosial-box{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    
    padding: 22px 28px;
    right: 36px;
    top: 66px;
    z-index: 120;
    background: radial-gradient(62.36% 62.36% at 50% 50%, #BAEFFE 0%, #2577CA 100%);
    border-radius: 50px;
    &>a{
        transition: all 1s;
        &:hover{
            transform: scale(1.2);
        }
    }
}

.connect-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &>span{
        font-family: 'Ruslan Display';
        font-style: normal;
        font-weight: 400;
        font-size: 46px;
        line-height: 50px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 54px;
    }
}

@media (max-width:1440px) {
    .pop-up-mint{
        max-width: 1024px;
        max-height: 720px;
        left: calc((100% - 1024px) / 2);
        top:10%;
    }
    
    .logo-mint {
        width: 101px;
        height: auto;
    }

    .bee-mint{
        width: 391px;
    }

    .hive-mint{
        width: 260px;
    }

    .klinavinok-mint{
        width: 247px;
        left: 0px;
        bottom: -70px;
    }

    .kalina-mint{
        width: 730px;
    }

    .img-hive-box {
        bottom: 70px;
    }

    .box-mint-case {
        max-width: 466px;
        height: 300px;
    }
}

@media (max-width:1000px) {
    .pop-up-mint{
        max-width: 800px;
        max-height: 600px;
        left: calc((100% - 800px) / 2);
    }

    .sosial-box{
        width: 290px;
        padding: 12px 18px;
        right: 26px;
        top: 56px;
    }

    .bee-mint{
        width: 341px;
    }

    .hive-mint{
        width: 170px;
        z-index: 110;
    }

    .klinavinok-mint{
        
        width: 187px;
        left: -10px;
        bottom: -60px;
        z-index: 110;
    }

    .kalina-mint{
        display: none;
        width: 590px;
    }
}

@media (max-width:800px) {
    .pop-up-mint{
        max-width: 375px;
        max-height: 600px;
        left: calc((100% - 375px) / 2);
        top: 1%;
    }

    .connect-box > span{
        margin-bottom: -6px;
        font-size: 25px;
    }

    .sosial-box{
        width: 190px;
        right: 16px;
        top: 56px;
    }

    .logo-mint{
        top: 13px;
        left: 16px;
    }

    .bee-mint{
        width: 211px;
    }

    .img-hive-box {
        bottom: 30px;
    }

    .img-hive-box{
        right: -10px;
        bottom: 50px;
    }

    .hive-mint{
        width: 137px;
        left: -10px;
        bottom: -40px;
    }

    .klinavinok-mint{
        width: 137px;
        left: -10px;
        bottom: -60px;
        z-index: 110;
    }

    .kalina-mint{
        width: 410px;
    }
}