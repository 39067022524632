.partner{
    display: flex;
    flex-direction: column;
    align-items: center;
    &_title{
        display: block;
        max-width: 558px;
        font-family: "Ruslan Display", cursive;
        font-size: 46px;
        color: #2577CA;
        text-align: center;
        margin: 150px 0 60px;
    }
}

.partner-carusel{
    position: relative;
    display: flex;
    &-screen{
        position: relative;
        display: flex;
        flex-direction: row;
        width: 1997px;
        animation:carusel 20s linear infinite;
        &_block{
            display: flex;
            flex-direction: row;
            align-items: center;

            &>img{
                max-height: 80px;
                margin: 0 20px;
                -webkit-filter: drop-shadow(0px 5px 4px #00000024);
                filter: drop-shadow(0px 5px 4px #00000024);
            }
        }
    }
}

@keyframes carusel {
    0%{
        transform: translateX(0%);
    }
    
    100%{
        transform: translateX(-100%);
    }
}