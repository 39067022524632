.bee-line{
    position: relative;
    display: block;
    bottom: -135px;
    left: 28px;
    z-index: 1;
    -webkit-filter: drop-shadow(0px 5px 4px #00000024);
    filter: drop-shadow(0px 5px 4px #00000024);

    img{
        width: 163px;
        height: 191px;
        margin: 0 2.5px;
    }
    
    &_leir0{
        position: relative;
        display: flex;
        justify-content: center;
        left: -196px;
        z-index: 2;
    }
    
    &_leir1{
        display: flex;
        position: relative;
        top: -40px;
        left: -110px;
        justify-content: center;
    }
}
.bee-line-case{
    position: absolute;
    

    &__dis{
        display: none;
    }

    &__beack{
        left: -167px;

    }
}

@keyframes dis0L{
    0%{
        display: none;
    }

    50%{
        display: block;
    }

    100%{
        display: block;
    }
}

@keyframes dis1L{
    0%{
        display: block;
    }

    50%{
        display: none;
    }

    100%{
        display: none;
    }
}

.flower-token{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    &>img{
        width: 87px;
        margin: 0 0 50px;
        -webkit-filter: drop-shadow(0px 5px 4px #00000024);
        filter: drop-shadow(0px 5px 4px #00000024);
    }

    &_title{
        font-size: 24px !important;
        font-weight: 600 !important;
        margin-bottom: 14px;
    }
    &>span{
        max-width: 400px;
        font-family: 'Montserrat', sans-serif; 
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        
    }

    &>span:first-child{
        font-size: 24px;
        font-weight: 600;
    }
}

.city-token{
    position: relative;
    width: 174px;
    margin: 0 20px 0 0;
    &_img{
        position: relative;
        z-index: 1;
    }

    &_title{
        display: block;
        position: absolute;
        top: 90px;
        font-family: 'Montserrat', sans-serif; 
        font-size: 20px;
        font-weight: 700;
        width: 174px;
        z-index: 2;
        text-align: center;
        text-transform: uppercase;
    }
}
.city-token:last-child{
    margin: 0;
}

.arrow-up{
    position: fixed;
    right: 20px;
    bottom: 20px;
    transition: 1s;
    z-index: 20;
    cursor: pointer;
    -webkit-filter: drop-shadow(6px 4px 5px rgba(0, 0, 0, 0.7));
    filter: drop-shadow(6px 4px 5px rgba(0, 0, 0, 0.7));

    &:hover{

    }
}

.arrow-up_dis{
    bottom: -100px;
}

.text{
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #ffffff;

}