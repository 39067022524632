.hcih{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &_title{
        display: block;
        font-family: "Ruslan Display", cursive;
        font-size: 46px;
        color: #FDCE26;
        margin: 150px 0 60px;
    }

    &_subtitle{
        width: 90%;
        max-width: 670px;
        font-family: "Montserrat", sans-serif;
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        margin: 0 auto 100px;
        white-space: break-spaces;
    }

    &_subtitle0{
        max-width: 630px;

    }
}

.danat-case{
    display: flex;
    flex-direction: column;
    position: relative;

    &_title{
        display: flex;
        align-items: flex-end;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        
        &>span:last-child{
            display: block;
            max-width: 210px;
        }
    }

    &_caunt{
        position: relative;
        left: -15px;    
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 86px;
        line-height: 105px;
        color: #FDCE26;
        -webkit-filter: drop-shadow(0px 5px 4px #00000024);
        filter: drop-shadow(0px 5px 4px #00000024);
    }

    &_button{
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;
        padding: 0;
        text-align: center;
        text-decoration: none !important;
        -webkit-filter: drop-shadow(0px 5px 4px #00000024);
        filter: drop-shadow(0px 5px 4px #00000024);
        &>button{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 239px;
            height: 58px;

            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            text-transform: uppercase;
            
            border: 0px;
            background: radial-gradient(62.36% 62.36% at 50% 50%, #FFEBA6 0%, #FDCE26 100%);
            cursor: pointer;
            &>img{
                position: absolute;
                left: -30px;
            }
        }
        
        a{
            display: flex;
            flex-direction: row;
            justify-content: center;
            position: relative;
            padding: 0;
            text-align: center;
            text-decoration: none !important;
            -webkit-filter: drop-shadow(0px 5px 4px #00000024);
            filter: drop-shadow(0px 5px 4px #00000024);

            &>button{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 239px;
                height: 58px;

                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                text-transform: uppercase;
                
                border: 0px;
                background: radial-gradient(62.36% 62.36% at 50% 50%, #FFEBA6 0%, #FDCE26 100%);
                cursor: pointer;
                &>img{
                    position: absolute;
                    left: -80px;
                }
            }
        }
    }

    &_img{
        -webkit-filter: drop-shadow(0px 5px 4px #00000024);
        filter: drop-shadow(0px 5px 4px #00000024);
    }
}

.pop-up-donate{
    position: absolute;
    top: 510px;
    padding: 82px 92px;
    background:radial-gradient(62.36% 62.36% at 50% 50%, #FFEBA6 0%, #FDCE26 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 100;

    &_title{
        font-family: 'Ruslan Display';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 26px;
        font-variant: small-caps;
        text-transform: uppercase;
        color: #333333;
        
    }

    &_subtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color:#2577CA;
    }

    &>div{
        display: flex;
        flex-direction: column;
        margin: 15px 0 49px 0;
    }

    &_cripta-copi{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        
        &>img{
            margin: 0px 0 0 30px;
            cursor: pointer;
        }
    }

    &_close{
        position: absolute;
        top: 20px;
        right: 20px;
        background: transparent;
        border: 0;
        cursor: pointer;
    }
    &_dis{
        display: none;
        color:transparent;
        background-color: transparent;
    }
}

// .pop-up-mint{
//     top:50% !important;
//     padding: 34px 40px;
//     left: 10%;
//     &>span{
//         font-family: "Ruslan Display";
//         font-style: normal;
//         font-weight: 400;
//         font-size: 24px;
//         line-height: 26px;
//         font-feature-settings: "smcp";
//         font-variant: small-caps;
//         text-transform: uppercase;
//         color: #333333;
//     }
// }

.copi-modail{
    position: absolute;
    left: 35%;
    align-items: center;

    animation: act 1s;
    &>span{
        font-family: "Ruslan Display";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 26px;
        font-feature-settings: "smcp";
        font-variant: small-caps;
        color: #FDCE26;
        border: 0px solid transparent;
        padding: 5px 10px;
        border-radius: 20px;
        background: #2577CA;
        transition: 1s all;
    }
}

.copi-modail_dis{
    &>span{
        color:transparent;
        background: transparent;
    }
} 

.span-case{
    display: flex;
    flex-wrap: wrap;

    &>img{
        margin-left: 10px;
    }
}


// @media (max-width:1000px){
//     .pop-up-donate{
//         width: 800px;
//     }
// }
