body{
    margin: 0;
    &::-webkit-scrollbar{
        width: 10px;
        background: #287acb;
    }

    &::-webkit-scrollbar-thumb{
        border-radius: 20px;
        background: #FDCE26;
        
    }
    &::-webkit-scrollbar-thumb:hover{
        background: #fac607;
    }

    &::-webkit-scrollbar-track {
        
        border:0px;
        border-radius: 0px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.34); 

    }
}

.row{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
}

#body-website{
    position: relative;
    overflow: hidden;
}

.button-nav{
    background: transparent;
    border: 0px solid transparent;
    color: #2577CA;
    font-family: 'Montserrat', sans-serif; 
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    transition: 1s;

    &:hover{
        color: #ffffff;
        transform: scale(1.1);
    }
}

.button-leng{
    background: transparent;
    border: 0px solid transparent;
    font-family: 'Montserrat', sans-serif; 
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
    transition: 1s;
    &:hover{
        // color: #ffffff;
        transform: scale(1.1);
    }
}

.button-location{
    width: 218px;
    height: 49px;
    background:radial-gradient(62.36% 62.36% at 50% 50%, #BAEFFE 0%, #2577CA 100%);
    border: 0px transparent;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
    transition: 1s;
    white-space: break-spaces;
    &:hover{
        -webkit-box-shadow: 0px 0px 29px 10px #2577CA; 
        box-shadow: 0px 0px 29px 10px #2577CA;
    }
}

.yellow{
    color: #000000;
    background: radial-gradient(62.36% 62.36% at 50% 50%, #FFEBA6 0%, #FDCE26 100%);

    &:hover{
        box-shadow: 0px 0px 29px 10px #FDCE26 ;
    }
}

.title{
    -webkit-filter: drop-shadow(0px 5px 4px #00000024);
    filter: drop-shadow(0px 5px 4px #00000024);
}

span{
    color: #333333;
}

ul li{
    color: #333333;
}

button{
    color: #333333
}

.right{
    right: 8% !important;
    left: auto !important;
}